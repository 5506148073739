import React from 'react';
import Loadable from 'react-loadable';
import {
  AccessibilityAuditComponentResultsSection,
  ClassnamePartial,
  CodeSnippet,
  EnvironmentPartial,
  List,
  PageNavigation,
  Paragraph,
  PlatformTab,
  PropList,
  PropListItem,
  QaIdPartial,
  Section
} from '../../../../components';

const Text = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Text;
    return <Component {...props} />;
  }
});

const Toggle = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.Toggle;
    return <Component {...props} />;
  }
});

const DataCard = Loadable({
  loader: () => import('@hudl/uniform-ui-components'),
  loading: () => null,
  render(loaded, props) {
    const Component = loaded.DataCard;
    return <Component {...props} />;
  }
});

const snippet = require('raw-loader!../../../../data/snippets/toggle.example');
const stateSnippet = require('raw-loader!../../../../data/snippets/toggle-state.example');
const toggleListSnippet = require('raw-loader!../../../../data/snippets/toggle-list.example');

const WebTab = () => {
  return (
    <PlatformTab>
      <PageNavigation links={['Toggle', 'Toggle List']} />
      <Section title="Toggle">
        <CodeSnippet
          scope={{ React: React, Toggle: Toggle, DataCard: DataCard }}
          code={snippet}
          platform="react"
          gitHubLink="modules/visualizations/toggle"
        />

        <Section title="Props">
          <PropList header="Visual">
            <ClassnamePartial componentName="toggle" />

            <EnvironmentPartial />

            <PropListItem name="items" types={['VisualizationData[]']} isRequired>
              <Text>Data used to populate toggle buttons.</Text>
            </PropListItem>

            <PropListItem name="selectedLabels" types={['string[]']}>
              <Text>
                Array of labels corresponding to the currently selected items. The selected labels should match labels
                provided in the items prop. For toggles, only one value should be selected at a time.
              </Text>
            </PropListItem>
          </PropList>

          <PropList header="Events">
            <PropListItem name="onToggleItem" types={['(label: string) => void']}>
              <Text>
                Responds to toggle button press. The provided label string matches the label of the toggled item as
                provided in the items prop.
              </Text>
            </PropListItem>
          </PropList>

          <PropList header="Miscellaneous">
            <QaIdPartial componentName="toggle" />
          </PropList>
        </Section>

        <Section title="Managing State">
          <Text className="uni-margin--half--bottom">
            To make managing the state of the selected toggle buttons easier, we created <code>useToggleArray()</code>,
            which is a custom React hook that accepts an array of labels representing the initally selected items, and
            returns an array with two values:
          </Text>
          <List type="unordered" className="uni-margin--one--bottom">
            <li>
              <code>selectedLabels</code>: An array of currently selected labels to pass through the{' '}
              <code>selectedLabels</code> prop.
            </li>
            <li>
              <code>handleToggleItem</code>: A callback function to pass through <code>onToggleItem</code> to handle
              toggling the selected state of each item.
            </li>
          </List>
          <CodeSnippet disableCodeEditing code={stateSnippet} platform="react" id="toggle-state-snippet" />
        </Section>

        <AccessibilityAuditComponentResultsSection platform="React" componentName="Modules / Toggle" />
      </Section>

      <Section title="Toggle List">
        <Paragraph>
          Toggle lists are used to show multiple related toggles in a vertical list with left-aligned labels. The label
          prop must be used on toggles that are within toggle lists.
        </Paragraph>
        <CodeSnippet
          code={toggleListSnippet}
          platform="react"
          gitHubLink="modules/visualizations/toggle/toggle-list"
          id="toggle-list-snippet"
          disableCodeEditing
        />

        <Section title="Toggle List Props">
          <PropList header="Visual">
            <PropListItem name="children" types={['ReactNode']} isRequired>
              <Text>The toggles to display in the toggle list.</Text>
            </PropListItem>

            <ClassnamePartial componentName="toggle list" />

            <EnvironmentPartial />
          </PropList>

          <PropList header="Miscellaneous">
            <QaIdPartial componentName="toggle list" />
          </PropList>
        </Section>
      </Section>

      <AccessibilityAuditComponentResultsSection platform="React" componentName="Modules / Toggle List" />
    </PlatformTab>
  );
};

export default WebTab;
